import { Injectable, inject } from '@angular/core';
import { ApiService } from '@lorient-emploi-front/core/http-client';
import { Occupation } from '../models/occupation.model';


@Injectable({
  providedIn: 'root'
})
export class OccupationService {
  private readonly _apiService = inject(ApiService);

  getOccupation(id: string) {
    return this._apiService.get<Occupation>(`/occupations/${id}`);
  }
}