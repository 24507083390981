import { Injectable, computed, inject, signal } from '@angular/core';
import { ApiService } from '@lorient-emploi-front/core/http-client';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { EntitiesResponse, Entity, LatestEntitiesResponse } from '../models/entity.model';
import { Card } from '@lorient-emploi-front/shared/ui/components';
import { ResourcesService } from './resources.service';

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  private readonly _apiService = inject(ApiService);
  private readonly _resourceService = inject(ResourcesService);
  readonly latestNews = signal<Entity[]>([]);
  readonly cards = computed<Card[]>(() => this._resourceService.buildCards(this.latestNews()));

  getNews(page = 0): Observable<EntitiesResponse> {
    const params = new HttpParams().set('page', page.toString());
    return this._apiService.get<EntitiesResponse>('/news', params);
  }

  getLatestNews() {
    this._apiService.get<LatestEntitiesResponse>('/news/latest').subscribe(response => {            
      this.latestNews.set([...response.data.slice(0, 3)]);
    });
  }
}
