import { Injectable, inject, signal } from '@angular/core';
import { ApiService, RequestState } from '@lorient-emploi-front/core/http-client';
import { firstValueFrom } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SiteSettingsService {
  private readonly _apiService = inject(ApiService);

  readonly canSignin = signal<RequestState<boolean>>({ status: 'idle', data: null });

  async getShowUserCreation(): Promise<boolean> {
    try {
      const response = await firstValueFrom(this._apiService.get<boolean>('/site-settings/user-creation'));
      this.canSignin.set({
        status: 'success',
        data: response
      });
      return response;
    } catch (err) {
      this.canSignin.set({
        status: 'error',
        data: null,
        message: ''
      });
      return false;
    }
  }
}
