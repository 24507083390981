import { Injectable, computed, inject, signal } from '@angular/core';
import { ApiService } from '@lorient-emploi-front/core/http-client';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { EntitiesResponse, Entity, LatestEntitiesResponse } from '../models/entity.model';
import { ResourcesService } from './resources.service';
import { Card } from '@lorient-emploi-front/shared/ui/components';

@Injectable({
  providedIn: 'root'
})
export class SectorsService {
  private readonly _apiService = inject(ApiService);
  private readonly _resourceService = inject(ResourcesService);
  readonly sectors = signal<Entity[]>([]);
  readonly cards = computed<Card[]>(() => this._resourceService.buildCards(this.sectors()));

  getSector(id: string): Observable<EntitiesResponse> {
    return this._apiService.get<EntitiesResponse>(`/sectors/${id}`);
  }

  getRandomSectors(): void {
    this._apiService.get<LatestEntitiesResponse>('/sectors/random').subscribe(response => {
      this.sectors.set(response.data.slice(0, 6));
    });
  }

  getSectors(page = 0): Observable<EntitiesResponse> {
    const params = new HttpParams().set('page', page.toString());
    return this._apiService.get<EntitiesResponse>('/sectors', params);
  }
}
