import { Injectable, inject } from '@angular/core';
import { ApiService } from '@lorient-emploi-front/core/http-client';
import { EntitiesResponse, Entity } from '../models/entity.model';
import { HttpParams } from '@angular/common/http';
import { Pagination } from '../models/pagination.model';
import { patchState, signalState } from '@ngrx/signals';
import { ResourcesService } from './resources.service';

type SearchState = { 
  searchResult: Entity[];
  pagination: Pagination;
  isLoading: boolean;
  searchTerm: string;
  lastPageRequested: number;
  filterModels: string[];
  filterRange: number;
  filterLocation: string;
  filterLocationLat: number;
  filterLocationLong: number;
};

@Injectable({
  providedIn: 'root'
})
export class ResearchService {
  private _resourcesService = inject(ResourcesService);
  private readonly _apiService = inject(ApiService);
  private readonly _searchState = signalState<SearchState>({
    searchResult: [],
    pagination: { current_page: 1, total: 0, per_page: 0 },
    isLoading: true,
    searchTerm: '',
    lastPageRequested: 1,
    filterModels: [],
    filterRange: 0,
    filterLocation: '',
    filterLocationLat: 0,
    filterLocationLong: 0
  });

  readonly searchResult = this._searchState.searchResult;
  readonly pagination = this._searchState.pagination;
  readonly isLoading = this._searchState.isLoading;
  readonly searchTerm = this._searchState.searchTerm;
  readonly filterModels = this._searchState.filterModels;
  readonly lastPageRequested = this._searchState.lastPageRequested;
  readonly filterRange = this._searchState.filterRange;
  readonly filterLocation = this._searchState.filterLocation;
  readonly filterLocationLat = this._searchState.filterLocationLat;
  readonly filterLocationLong = this._searchState.filterLocationLong;

  resetSearchParams() {
    patchState(this._searchState, {
      pagination: { current_page: 1, total: 0, per_page: 0 },
      searchResult: [],
      searchTerm: '',
      lastPageRequested: 1,
      filterModels: [],
      filterRange: 0,
      filterLocation: '',
      filterLocationLat: 0,
      filterLocationLong: 0
    });
  }

  updateSearchParams(page: number, term: string, models: string[], location: string, range: number, lat: number, long: number) {
    patchState(this._searchState, {
      lastPageRequested: page,
      searchTerm: term,
      filterModels: models,
      filterRange: range,
      filterLocation: location,
      filterLocationLat: lat,
      filterLocationLong: long
    });
  }

  getSearch(page: number, term: string = '') {    
    patchState(this._searchState, {
      pagination: { current_page: page, total: 0, per_page: 0 },
      isLoading: true
    });
    let params = new HttpParams()
    .set('page', page.toString())
    .set('term', term)
    .set('city', this.filterLocation())
    .set('lat', this.filterLocationLat())
    .set('long', this.filterLocationLong())
    .set('range', this.filterRange());

    this.filterModels().forEach(model => {
      params = params.append('models[]', model);
    });

    this._apiService.get<EntitiesResponse>('/search', params).subscribe({  
      next: response => patchState(this._searchState, {
        isLoading: false,
        searchResult: response.data.map(entity => ({
          ...entity,
          link: `/${this._resourcesService.getUrl(entity.type)}/${entity.slug}` 
        })),
        pagination: response.pagination,
        searchTerm: term,
        lastPageRequested: page,
      }),
      error: err => patchState(this._searchState, { isLoading: false })
    });
  }
}
