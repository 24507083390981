import { Injectable, inject } from '@angular/core';
import { ApiService } from '@lorient-emploi-front/core/http-client';
import { SingleResource } from '../models/single-resource.model';
import { Entity } from '../models/entity.model';

@Injectable({
  providedIn: 'root'
})
export class SingleService {
  private readonly _apiService = inject(ApiService);

  getResource(type: string, id: string) {
    return this._apiService.get<SingleResource>(`/${type}/${id}`);
  }

  getRandomResource() {
    return this._apiService.get<Entity>(`/random`);
  }
}
