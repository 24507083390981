import { isPlatformBrowser } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ApiService } from '@lorient-emploi-front/core/http-client';
import { filter } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  private readonly _router = inject(Router);
  private readonly _apiService = inject(ApiService);
  private readonly _platformId = inject(PLATFORM_ID);

  constructor() {}

  initializeSeoStatistic() {
    this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((routeEvent) => {        
        const reference_id = (routeEvent as NavigationEnd).url;
        const navigation = this._router.getCurrentNavigation();

        const sendAnalytics = navigation?.extras.state?.['sendAnalytics'];

        if (sendAnalytics === false) {
          return;
        }

        if (isPlatformBrowser(this._platformId)) {
          this.postPageView(reference_id);
        }
      });
  }

  postPageView(reference_id: string) {
    this._apiService.post('/seo-statistics/page-view', {reference_id}).subscribe();
  }

  postMapClick(reference_id: string, resource_type: string) {
    this._apiService.post('/seo-statistics/map-click', {reference_id, resource_type}).subscribe();
  }
}
