import { Injectable, inject } from '@angular/core';
import { patchState, signalState } from '@ngrx/signals';
import { Pagination } from '../models/pagination.model';
import { EventEntity } from '../models/entity.model';
import { HttpParams } from '@angular/common/http';
import { ApiService } from '@lorient-emploi-front/core/http-client';

type SearchState = { 
  searchResult: EventEntity[];
  pagination: Pagination;
  isLoading: boolean;
  lastPageRequested: number;
  filterTypes: string[];
  filterYear: number;
  filterMonth: number;
};

@Injectable({
  providedIn: 'root'
})
export class AgendaService {
  private readonly _apiService = inject(ApiService);
  private readonly _searchState = signalState<SearchState>({
    searchResult: [],
    pagination: { current_page: 1, total: 0, per_page: 0 },
    isLoading: true,
    lastPageRequested: 1,
    filterTypes: [],
    filterYear: new Date().getFullYear(),
    filterMonth: new Date().getMonth() + 1
  });

  readonly searchResult = this._searchState.searchResult;
  readonly pagination = this._searchState.pagination;
  readonly isLoading = this._searchState.isLoading;
  readonly filterTypes = this._searchState.filterTypes;
  readonly lastPageRequested = this._searchState.lastPageRequested;
  readonly filterYear = this._searchState.filterYear;
  readonly filterMonth = this._searchState.filterMonth;

  updateSearchParams(year: number, month: number) {
    patchState(this._searchState, {
      filterYear: year,
      filterMonth: month
    });
  }

  updateLoadingState(isLoading: boolean) {
    patchState(this._searchState, { isLoading });
  }

  getSearch() {
    patchState(this._searchState, {
      isLoading: true
    });

    let params = new HttpParams()
    .set('year', this.filterYear())
    .set('month', this.filterMonth())

    this._apiService.get<EventEntity[]>('/events/search', params).subscribe({  
      next: response => patchState(this._searchState, {
        isLoading: false,
        searchResult: response
      }),
      error: err => patchState(this._searchState, { isLoading: false })
    });
  }
}
